import { API_ROOT as API_BASE, NICK_API_ROOT as NICK_API_ROOT } from './environment'
export default {
  LOGIN: API_BASE + '/login',
  WHOAMI: API_BASE + '/whoami',
  FACILITIES_ID: API_BASE + '/facilities/{id}',
  STATIONS: API_BASE + '/stations',
  ZONES: API_BASE + '/zones',
  CURRENCIES: API_BASE + '/currencies',
  PRICING_GROUPS: API_BASE + '/pricing-groups',
  PERIPHERALS: API_BASE + '/peripherals',
  PERIPHERALS_ID: API_BASE + '/peripherals/{id}',
  OPERATORS: API_BASE + '/operators',
  BOOKINGS: API_BASE + '/bookings',
  PAYMENT_METHODS: API_BASE + '/payment-methods',
  BOOKINGS_ID: API_BASE + '/bookings/{id}',
  BOOKINGS_ID_GUESTS: API_BASE + '/bookings/{id}/guests',
  PROFILES_ID: API_BASE + '/profiles/{id}',  
  GUESTS: API_BASE + '/guests',  
  GUESTS_ID: API_BASE + '/guests/{id}',
  ACCOUNTS_ID: API_BASE + '/accounts/{id}',
  ORDERS_ID: API_BASE + '/orders/{id}',
  ORDERS_ID_REFUNDS: API_BASE + '/orders/{id}/refunds',
  SERVICES: API_BASE + '/services',
  TRANSACTIONS: API_BASE + '/transactions',
  TRANSACTIONS_ID: API_BASE + '/transactions/{id}',
  ORDER_ID_TRANSACTIONS: API_BASE + '/orders/{id}/transactions',
  ADOPTED_PERIPHERALS: 'http://localhost:2025/api/peripherals/adopted',
  POS_ME: NICK_API_ROOT + '/pos/me',
  POS_CONFIG: NICK_API_ROOT + '/pos/config',
  POS_GUESTS_ID_KEY: NICK_API_ROOT + '/pos/guests/{id}/key',
  POS_PRINTERS_ID_SHIFTS: NICK_API_ROOT + '/pos/printers/{id}/shifts',
  POS_SHIFTS: NICK_API_ROOT + '/pos/shifts',
  POS_SHIFTS_ID: NICK_API_ROOT + '/pos/shifts/{id}',
  POS_SHIFTS_ID_TICKET: NICK_API_ROOT + '/pos/shifts/{id}/ticket',
  POS_PASSES: NICK_API_ROOT + '/pos/passes',
  POS_PASSES_ID_ORDER_ID_TICKET: NICK_API_ROOT + '/pos/passes/{passId}/orders/{orderId}/ticket',
  POS_PACKAGES: NICK_API_ROOT + '/pos/packages',
  POS_PACKAGES_BOOK: NICK_API_ROOT + '/pos/packages/book',
  POS_PACKAGES_MANUAL: NICK_API_ROOT + '/pos/packages/manual',
  POS_ORDERS_REFERENCE: NICK_API_ROOT + '/pos/orders/{reference}',
  POS_ORDERS: NICK_API_ROOT + '/pos/orders',
  POS_ORDERS_ID_TICKET: NICK_API_ROOT + '/pos/orders/{id}/ticket',
  POS_ORDER_REFUNDS_ID_TICKET: NICK_API_ROOT + '/pos/order-refunds/{id}/ticket',
  POS_ORDERS_ID_REFUND: NICK_API_ROOT + '/pos/orders/{id}/refund',
  POS_TRANSACTIONS_ID_TICKET: NICK_API_ROOT + '/pos/transactions/{id}/ticket',
  POS_TRANSACTIONS: NICK_API_ROOT + '/pos/transactions',
  POS_REFUND_REQUESTS: NICK_API_ROOT + '/pos/refund-requests',
  POS_REFUND_REQUESTS_ID_ACCEPT: NICK_API_ROOT + '/pos/refund-requests/{id}/accept',
  POS_REFUND_REQUESTS_ID_REJECT: NICK_API_ROOT + '/pos/refund-requests/{id}/reject',
  POS_REFUND_REQUESTS_ID_BANK_ACCOUNT_IMAGE: NICK_API_ROOT + '/pos/refund-requests/{id}/bank-account-image'
}
