import camelCaseKeys from 'camelcase-keys'
import { Tax } from '@/entities/Tax'

export class Package {
  constructor (params = {}) {
    const {
      id,
      name,
      taxes,
      typeId,
      categoryId,
      description,
      tokenAmount,
      mustBeBooked,
      tokenCurrencyId,
      maxDailyProducts,
      pricingGroupsWithPrices,
      maxTokenAmountOnAccount,
      currentAvailableProducts
    } = camelCaseKeys(params, { deep: true })
    this.id = id || null
    this.name = name || []
    this.typeId = typeId || null
    this.categoryId = categoryId || null
    this.description = description || []
    this.taxes = taxes.map(t => new Tax(t))
    this.tokenCurrencyId = tokenCurrencyId || null
    this.pricingGroupsWithPrices = pricingGroupsWithPrices || []
    this.currentAvailableProducts = typeof currentAvailableProducts === 'number' ? currentAvailableProducts : null
    this.tokenAmount = typeof tokenAmount === 'number' ? tokenAmount : 1
    this.mustBeBooked = typeof mustBeBooked === 'boolean' ? mustBeBooked : null
    this.maxDailyProducts = typeof maxDailyProducts === 'number' ? maxDailyProducts : null
    this.maxTokenAmountOnAccount = typeof maxTokenAmountOnAccount === 'number' ? maxTokenAmountOnAccount : null
  }
}
