import camelcaseKeys from 'camelcase-keys'

export class Order {
  constructor (params = {}) {
    const {
      id,
      comments,
      externalIds,
      orderLines,
      reference,
      sequence,
      station,
      stationId,
      ticketId,
      guestId,
      service,
      refunded,
      serviceId,
      createdAt,
      orderRefundId,
      paymentMethodType
    } = camelcaseKeys(params, { deep: true })
    this.id = id || null
    this.comments = comments || null
    this.externalIds = externalIds || null
    this.orderLines = orderLines || []
    this.reference = reference || null
    this.sequence = sequence || null
    this.station = station || null
    this.stationId = stationId || null
    this.ticketId = ticketId || null
    this.guestId = guestId || null
    this.service = service || null
    this.serviceId = serviceId || null
    this.createdAt = createdAt || null
    this.orderRefundId = orderRefundId || null
    this.paymentMethodType = paymentMethodType || null
    this.refunded = typeof refunded === 'boolean' ? refunded : null
  }
}
