import { ExternalId } from './ExternalId'
export class BookingType {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.attributes = params.attributes ? params.attributes : null
    this.name = params.name ? params.name : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      attributes: item.attributes,
      name: item.name
    }
  }
}
