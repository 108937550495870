import { ExternalId } from './ExternalId'
export class Booking {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.profile = params.profile ? params.profile : null
    this.profileId = params.profile_id ? params.profile_id : null
    this.checkin = params.checkin ? params.checkin : null
    this.checkout = params.checkout ? params.checkout : null
    this.extendedAccount = params.extendedAccount ? params.extendedAccount : null
    this.accountId = params.account_id ? params.account_id : null
    this.facility = params.facility ? params.facility : null
    this.facilityId = params.facility_id ? params.facility_id : null
    this.type = params.type ? params.type : null
    this.typeId = params.type_id ? params.type_id : null
    this.locator = params.locator ? params.locator : null
    this.isOnlineTicketingBooking = false
  }
}
