import { ExternalId } from './ExternalId'
export class Station {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.name = params.name ? params.name : null
    this.zone = params.zone ? params.zone : null
    this.zoneId = params.zone_id ? params.zone_id : null
    this.materialLoans = params.materialLoans ? params.materialLoans : []
    this.materialLoanIds = params.material_loan_ids ? params.material_loan_ids : []
    this.accessAreas = params.accessAreas ? params.accessAreas : []
    this.accessAreaIds = params.access_area_ids ? params.access_area_ids : []
    this.allowedCurrencies = params.allowedCurrencies ? params.allowedCurrencies : []
    this.allowedCurrencyIds = params.allowed_currency_ids ? params.allowed_currency_ids : []
    this.allowedPaymentMethods = params.allowedPaymentMethods ? params.allowedPaymentMethods : []
    this.allowedPaymentMethodIds = params.allowed_payment_method_ids ? params.allowed_payment_method_ids : []
    this.catalogId = params.catalog_id ? params.catalog_id : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      name: item.name,
      zoneId: item.zoneId,
      materialLoanIds: item.materialLoanIds,
      accessAreaIds: item.accessAreaIds,
      allowedCurrencyIds: item.allowedCurrencyIds,
      allowedPaymentMethodIds: item.allowedPaymentMethodIds,
      catalogId: item.catalogId
  }
  }
}
