import { ExternalId } from './ExternalId'
import { Address } from './Address'
export class Profile {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.documents = params.documents ? params.documents : []
    this.addresses = params.addresses ? params.addresses.map(a => new Address(a)) : []
    this.name = params.name ? params.name : null
    this.lastName = params.last_name ? params.last_name : null
    this.phoneNumber = params.phone_number ? params.phone_number : null
    this.email = params.email ? params.email : null
    this.dateOfBirth = params.date_of_birth ? params.date_of_birth : null
    this.gender = params.gender ? params.gender : null
    this.languageCode = params.language_code ? params.language_code : null
    this.dietRestrictions = params.diet_restrictions ? params.diet_restrictions : null
    this.attributes = params.attributes ? params.attributes : null
    this.type = params.type ? params.type : params.type_id
    this.typeId = params.type_id ? params.type_id : null
    this.category = params.category ? params.category : params.category_id
    this.categoryId = params.category_id ? params.category_id : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      attributes: item.attributes
    }
  }
}
