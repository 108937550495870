export class ExternalId {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.created = params.created ? params.created : null
    this.service = params.service ? params.service : params.service_id
    this.serviceId = params.service_id ? params.service_id : null
    this.qualifier = params.qualifier ? params.qualifier : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      service_id: item.serviceId
    }
  }
}
