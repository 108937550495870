import camelcaseKeys from 'camelcase-keys'
export class Facility {
  constructor (params = {}) {
    const { id, name, timezone, baseCurrencyId, defaultLanguageCode, allowedLanguageCodes } = params ? camelcaseKeys(params) : {}
    this.id = id || null
    this.name = name || null
    this.timezone = timezone || null
    this.baseCurrency = null
    this.baseCurrencyId = baseCurrencyId || null
    this.defaultLanguageCode = defaultLanguageCode || null
    this.allowedLanguageCodes = allowedLanguageCodes || []
  }
}
