import { ExternalId } from './ExternalId'
import snakeCaseKeys from 'snakecase-keys'

export class Guest {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.booking = params.booking ? params.booking : null
    this.bookingId = params.booking_id ? params.booking_id : null
    this.room = params.room ? params.room : null
    this.roomId = params.room_id ? params.room_id : null
    this.checkin = params.checkin ? params.checkin : null
    this.checkout = params.checkout ? params.checkout : null
    this.checkoutMoment = params.checkout_moment ? params.checkout_moment : null
    this.profile = params.profile ? params.profile : null
    this.profileId = params.profile_id ? params.profile_id : null
    this.extendedAccount = params.extendedAccount ? params.extendedAccount : null
    this.accountId = params.account_id ? params.account_id : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.dietRestrictions = params.diet_restrictions ? params.diet_restrictions : null
    this.type = params.type ? params.type : null
    this.typeId = params.type_id ? params.type_id : null
    this.active = params.active ? params.active : false
    this.category = params.category ? params.category : null
    this.categoryId = params.category_id ? params.category_id : null
    this.registrationCard = params.registrationCard ? params.registrationCard : null
    this.registrationCardTotalSteps = params.registrationCardTotalSteps ? params.registrationCardTotalSteps : null
    this.registrationCardCompletedSteps = params.registrationCardCompletedSteps ? params.registrationCardCompletedSteps : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return snakeCaseKeys(item)
  }
}
