import { ExternalId } from './ExternalId'
export class Room {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.sequence = params.sequence ? params.sequence : null
    this.name = params.name ? params.name : null
    this.type = params.type ? params.type : null
    this.typeId = params.type_id ? params.type_id : null
    this.facility = params.facility ? params.facility : params.facility_id
    this.facilityId = params.facility_id ? params.facility_id : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      name: item.name,
      facilityId: item.facilityId,
      typeId: item.typeId
    }
  }
}
