import { Currency } from '@/entities/Currency'
import { Account } from '@/entities/Account'
export class ExtendedAccount {
  constructor (params = {}) {
    this.account = params.account ? new Account(params.account) : null
    this.currencies = params.currencies.map((currency) => {
      return {
        postpaid: currency.postpaid,
        balance: currency.balance,
        currency: new Currency(currency.currency)
      }
    })
  }
}
