import { ExternalId } from './ExternalId'
export class Account {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.profile = params.profile ? params.profile_id : null
    this.profileId = params.profile_id ? params.profile_id : null
    this.active = params.active ? params.active : false
    this.facility = params.facility ? params.facility_id : null
    this.facilityId = params.facility_id ? params.facility_id : null
    this.attributes = params.attributes ? params.attributes : null
    this.pricingGroupId = params.pricing_group_id ? params.pricing_group_id : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      profileId: item.profileId,
      attributes: item.attributes,
      active: item.active
    }
  }
}
