import { ExternalId } from './ExternalId'
export class Peripheral {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.label = params.label ? params.label : null
    this.available = params.available ? params.available : false
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.type = params.type ? params.type : null
    this.lastSeen = params.last_seen ? params.last_seen : null
    this.hostname = params.hostname ? params.hostname : null
    this.model = params.model ? params.model : null
    this.serialNumber = params.serial_number ? params.serial_number : null
    this.mac = params.mac ? params.mac : null
    this.ip = params.ip ? params.ip : null
    this.stationId = params.station_id ? params.station_id : null
    this.station = params.station ? params.station : params.station_id
  }
}
