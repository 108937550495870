import camelcaseKeys from 'camelcase-keys'
import { ExternalId } from './ExternalId'
export class Transaction {
  constructor (params = {}) {
    const { id, type, guest, amount, account, created, station, concept, guestId, orderId, sequence, operator, currency, notified, serviceId, accountId, stationId, reference, attributes, operatorId, currencyId, externalIds, exchangeValue, paymentMethod, orderRefundId, paymentMethodId, exchangeCurrency, exchangeCurrencyId, relatedTransactions, relatedTransactionId, relatedTransactionIds } = camelcaseKeys(params)
    this.id = id || null
    this.type = type || null
    this.guestId = guestId || null
    this.created = created || null
    this.concept = concept || null
    this.orderId = orderId || null
    this.notified = notified || null
    this.sequence = sequence || null
    this.serviceId = serviceId || null
    this.accountId = accountId || null
    this.reference = reference || null
    this.station = station || stationId
    this.operatorId = operatorId || null
    this.attributes = attributes || null
    this.currencyId = currencyId || null
    this.guest = guest ? guest : guestId
    this.orderRefundId = orderRefundId || null
    this.account = account ? account : accountId
    this.stationId = stationId ? stationId : null
    this.paymentMethodId = paymentMethodId || null
    this.exchangeCurrency = exchangeCurrency || null
    this.operator = operator ? operator : operatorId
    this.currency = currency ? currency : currencyId
    this.exchangeCurrencyId = exchangeCurrencyId || null
    this.relatedTransactions = relatedTransactions || []
    this.amount = typeof amount === 'number' ? amount : null
    this.relatedTransactionId = relatedTransactionId || null
    this.relatedTransactionIds = relatedTransactionIds || []
    this.paymentMethod = paymentMethod ? paymentMethod : paymentMethodId
    this.exchangeValue = typeof exchangeValue === 'number' ? exchangeValue : null
    this.externalIds = externalIds ? externalIds.map((e) => new ExternalId(e)) : []
    this.exchangeAmount = typeof exchangeAmount === 'number' ? exchangeAmount : null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      external_id: item.externalId,
      related_transaction_id: item.relatedTransactionId,
      payment_method_id: item.paymentMethodId,
      exchange_currency_id: item.exchangeCurrencyId,
      exchange_value: item.exchangeValue,
      exchange_amount: item.exchangeAmount,
      amount: item.amount,
      type: item.type,
      account_id: item.accountId,
      guest_id: item.guestId,
      concept: item.concept,
      moment: new Date(),
      currency_id: item.currencyId,
      stationId: item.stationId,
      operator_id: item.operatorId
    }
  }
}
