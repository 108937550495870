import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export class Tax {
  constructor (params) {
    const { id, name, value } = camelcaseKeys(params ? params : {})
    this.id = id || null
    this.name = name || null
    this.value = value
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return snakecaseKeys(item)
  }
}
