import camelcaseKeys from 'camelcase-keys'
import { ExternalId } from './ExternalId'
export class Currency {
  constructor (params = {}) {
    const { id, name, displayName, type, externalIds } = camelcaseKeys(params)
    this.id = id || null
    this.name = displayName || name || null
    this.type = type || null
    this.externalIds = externalIds ? externalIds.map((e) => new ExternalId(e)) : []
    this.defaultExchangeRate = null
    this.exchangeRates = null
  }
}
