export default {
  fields: {
    id: 'ID',
    username_or_mail: 'Usuario o e-mail',
    password: 'Contraseña',
    facility: 'Instalación',
    station: 'Estación',
    reader_peripheral: 'Lector',
    printer_peripheral: 'Impresora',
    operator: 'Operador',
    device: 'Equipo',
    status: 'Estado',
    name: 'Nombre',
    last_name: 'Apellidos',
    children: 'Nº de niños',
    reference: 'Referencia',
    created: 'Creación',
    service: 'Servicio',
    total: 'Total',
    concept: 'Concepto',
    original_amount: 'Importe original',
    refunded_amount: 'Importe reembolsado',
    payment_method: 'Método de pago',
    ticket_reference: 'Referencia de entrada',
    order_reference: 'Referencia del pedido',
    pass: 'Tipo de entrada',
    currency: 'Divisa',
    change: 'Cambio',
    cash: 'Efectivo',
    amount: 'Cantidad',
    price: 'Precio',
    items: 'Productos',
    type: 'Tipo',
    email: 'Correo electrónico',
    full_name: 'Nombre completo',
    reject: 'Rechazar'
  },
  drawer: {
    current_session: 'Sesión actual',
    current_shift: 'Turno actual'
  },
  actions: {
    sign_in: 'Iniciar sesión',
    sign_out: 'Cerrar sesión',
    loading: 'Cargando',
    menu: 'Menú',
    cancel: 'Cancelar',
    continue: 'Continuar',
    confirm: 'Confirmar',
    accept: 'Aceptar',
    reject: 'Rechazar',
    close: 'Cerrar',
    retry: 'Reintentar',
    info_form: 'Ver {section}',
    clear: 'Vaciar',
    info: 'Detalles',
    validate: 'Validar',
    reset_purchase: 'Reiniciar compra',
    refund: 'Reembolsar',
    complete_transaction: 'Completar transacción',
    locate: 'Localizar',
    cancel_payment: 'Cancelar pago',
    reset_adjustments: 'Reiniciar ajustes'
  },
  sections: {
    settings: 'Ajustes',
    guest: 'Venta de entradas',
    ticket: 'Venta de entradas',
    shifts: 'Listado de turnos',
    ticket_order: 'Venta de entradas',
    ticket_validation: 'Validación de entradas',
    refund: 'Reembolso de pedido',
    refund_requests: 'Solicitudes de reembolso',
    balance_adjustment: 'Ajuste de Add-ons',
    package_order: 'Venta de add-ons'
  },
  ticket_order: {
    visitor: 'Visitante {number}',
    pricing_group: 'Precio aplicado',
    actions: {
      add_visitor: 'Añadir visitante',
      proceed_with_purchase: 'Continuar con la compra',
      edit_data: 'Editar datos',
      clear: 'Vaciar pedido'
    },
    confirm: {
      title: 'Completa el proceso de pago',
      text: 'Marca el pedido como completado sólo cuando hayas obtenido el pago del visitante.',
      confirm: 'Confirmar pago completado',
      cancel: 'Cancelar pago'
    },
    success: 'Compra realizada con éxito',
    error: {
      receipt: 'Ha ocurrido un error durante la impresión del recibo',
      payment: 'Ha ocurrido un error con el pago y no se ha podido completar el pedido'
    },
    loading: {
      complete: 'Solicitando compra',
      ticket: 'Generando ticket',
      print: 'Imprimiendo ticket'
    }
  },
  ticket_validation: {
    missing_booking: 'Entrada no encontrada. Comprueba si el localizador es válido.'
  },
  summary: {
    title: 'Resumen',
    actions: {
      continue: 'Completar compra',
      cancel: 'Cancelar compra',
      proceed_with: 'Continuar'
    },
    total: 'Total',
    empty_order: 'Añade elementos a tu pedido',
    empty_adjustments: 'Sin ajustes'
  },
  shifts: {
    required_shift: {
      title: 'Acción restringida',
      open_shift_required: 'Es necesario tener turno abierto'
    },
    fields: {
      opened: 'Abierto',
      opened_by: 'Abierto por',
      opened_since: 'Abierto desde',
      opened_until: 'Abierto hasta',
      closed: 'Cerrado',
      closed_by: 'Cerrado por',
      closed_since: 'Cerrado desde',
      closed_until: 'Cerrado hasta',
      cash: 'Efectivo',
      card: 'Tarjeta'
    },
    actions: {
      open: 'Abrir turno',
      close: 'Cerrar turno',
      close_operator: 'Cerrar turno de operario',
      close_printer: 'Cerrar turno de equipo',
      print: 'Imprimir'
    },
    errors: {
      device_has_active_shift: 'Este equipo tiene un turno abierto para otro operario. Termina el turno anterior para abrir uno nuevo.',
      operator_has_active_shift: 'El operario actual tiene un turno abierto en {device}. Termina el turno anterior para abrir uno nuevo.'
    },
    warnings: {
      long_shift: 'Turno de más de 12h',
      required: 'Se requiere turno de operario'
    },
    open_shift: {
      title: 'Abriendo turno para {operator} en {printer}',
      success: 'Turno abierto con éxito',
      error: {
        fallback: 'No se ha podido abrir turno',
        PRINTER_HAS_OPEN_SHIFT: 'Ya hay un turno abierto para la impresora configurada'
      }
    },
    close_shift: {
      title: 'Turno cerrado @:shifts.close_shift.for_operator @:shifts.close_shift.at_device',
      for_operator: 'para {operator}',
      at_device: 'en {device}',
      initial: 'Inicial',
      final: 'Final',
      difference: 'Diferencia',
      error: {
        ticket: 'Ha habido un error imprimiendo el ticket',
        SHIFT_IS_ALREADY_CLOSED: 'El turno ya está cerrado'
      },
      getting_ticket: 'Generando ticket',
      success: 'Turno cerrado con éxito'
    },
    status: {
      OPEN: 'Abierto',
      CLOSED: 'Cerrado'
    },
    details: 'Detalles del turno',
    opened_at_date: 'Abierto {date}',
    unknown_printer: 'otro dispositivo',
    loading: 'Cargando turno'
  },
  tickets: {
    name: 'Ticket',
    title: 'Información de entrada'
  },
  temp_login: {
    title: 'Introduzca credenciales de supervisor'
  },
  reader: {
    read_identifier: 'Leer brazalete',
    bring_wristband_closer: 'Acerca el brazalete al lector',
    attempting_connection: 'Esperando al lector',
    assigning_identifier: 'Asignando brazalete',
    error: {
      socket_connection: 'Error de conexión con el periférico. Comprueba que esté correctamente conectado y activo.',
      FALLBACK: {
        title: 'Error de comunicación',
        description: 'Contacta con un administrador si el problema persiste'
      },
      BUSY: {
        title: 'Lector ocupado'
      },
      TIMEOUT: {
        title: 'Petición caducada'
      },
      ACTION_NOT_IMPLEMENTED: {
        title: 'Acción no implementada',
        description: 'Comprueba las actualizaciones del controlador de periféricos'
      },
      KEY_SERVER_ERROR: {
        title: 'Error en el servidor de llaves'
      },
      TAG_TYPE_NOT_SUPPORTED: {
        title: 'El tipo de identificador no es válido'
      },
      TAG_ENCODING_ERROR: {
        title: 'Error en la codificación de la llave'
      },
      ENTITY_DOES_NOT_EXIST: {
        title: 'Este identificador no tiene un huésped asociado'
      },
      NO_MATCH: {
        title: 'Lector desconectado'
      },
      auth_error: {
        title: 'Error de autenticación',
        description: 'Comprueba la configuración del controlador de periféricos'
      },
      INVALID_ACTION: {
        title: 'Acción no implementada',
        description: 'Comprueba las actualizaciones del controlador de periféricos'
      }
    },
    success: {
      key_generated: 'Brazalete asignado correctamente',
      key_cancelled: 'Brazalete desasignado correctamente'
    }
  },
  bookings: {
    valid_on: 'Válido el',
    valid_from: 'Válido desde',
    valid_to: 'hasta',
    visitors: 'Visitantes',
    purchase_packages: 'Comprar Add-ons',
    details: 'Ficha',
    cancelled: 'Cancelado',
    assigned_wristbands: 'Brazaletes asignados',
    park_visitor: 'Visitante del parque',
    hotel_guest: 'Huésped de hotel',
    ticket_purchase: 'Compra de entradas',
    online_purchase: 'Compra online'
  },
  orders: {
    receipt: 'Recibo',
    refunded: 'Reembolsado',
    order_refund: 'Reembolso de pedido',
    confirm_refund: 'Se devolverá el importe total del pedido.',
    loading: {
      complete: 'Solicitando reembolso',
      ticket: 'Generando recibo',
      print: 'Imprimiendo recibo'
    },
    error: {
      refund: 'Ha ocurrido un error durante el reembolso',
      receipt: 'Ha ocurrido un error durante la impresión del recibo'
    },
    success: {
      refund: 'Se ha reembolsado el pedido correctamente'
    }
  },
  payment_methods: {
    room_charge: 'Cobro a habitación'
  },
  guests: {
    info_title: 'Información del huésped',
    assign_wristband: 'Asignar brazalete',
    non_assignable_wristband_hotel_guest: 'Los brazaletes de huéspedes deben asignarse en la recepción del hotel.',
    non_unassignable_wristband_hotel_guest: 'Los brazaletes de huéspedes solo pueden desasignarse en la recepción del hotel.',
    unassign_wirstband: 'Desasignar brazalete',
    disabled: 'Deshabilitado',
    disabled_topup_balance_hotel: 'Los huéspedes solo pueden recargar en el hotel',
    visitor_disabled: 'Visitante deshabilitado',
    account_required: 'Cuenta obligatoria',
    show_currencies_with_balance: 'Mostrar divisas sin saldo',
    transactions: 'Transacciones',
    package_purchases: 'Add-ons adquiridos',
    no_list_transactions_permission: 'Para visualizar las transacciones del visitante, es necesario el permiso para "Listar transacciones"',
    actions: {
      disable_all: 'Cancelar entrada',
      disable_all_unavailable_guests: 'Las entradas con huéspedes solo pueden cancelarse desde el hotel',
      disable_all_unavailable_ticketing: 'Las entradas realizadas por venta online solo pueden cancelarse desde la plataforma de venta online'
    },
    type: {
      INFANT: 'Infante',
      CHILD: 'Niño',
      TEENAGER: 'Adolescente',
      ADULT: 'Adulto'
    },
    confirm: {
      unassign_wirstband: '¿Seguro que quieres desasignar el brazalete de este visitante?',
      disable_all: '¿Seguro que quieres deshabilitar todos los visitantes de esta reserva? Los huéspedes deshabilitados no podrán comprar Add-ons.'
    },
    errors: {
      unassign_wirstband: 'No se pudo desasignar el brazalete'
    }
  },
  accounts: {
    amount: 'Cantidad',
    balance: 'Saldo',
    actions: {
      top_up_balance: 'Añadir saldo',
      confirm_completed_payment: 'Confirmar pago completado'
    },
    top_up_warning: 'Asegúrate de cobrar el pago al visitante antes de marcarlo como completado.'
  },
  packages: {
    name: 'Add-on',
    package_name: 'Nombre del Add-on',
    top_up_packages: 'Añadir Add-ons',
    search: 'Nombre del Add-on',
    daily_limit_exceed: 'Añadir Add-ons sobrepasaría el límite establecido por día.',
    account_limit_exceed: 'Añadir Add-ons sobrepasaría el límite establecido por cuenta.',
    fields: {
      current: 'Adquiridos',
      current_daily: 'Disponibles hoy',
      max: 'Máx.',
      max_daily: 'Límite por día'
    },
    warnings: {
      account_limit_exceeding_packages: 'Se sobrepasará el límite por cuenta para los siguientes Add-ons:',
      daily_limit_exceeding_packages: 'Se sobrepasará el límite diario para los siguientes Add-ons:'
    },
    success: {
      purchase_completed: 'Compra realizada con éxito'
    }
  },
  package_order: {
    loading: {
      complete: 'Solicitando compra',
      ticket: 'Generando ticket',
      print: 'Imprimiendo ticket'
    }
  },
  order_refund: {
    missing_order: 'No se ha encontrado el pedido. Comprueba que la referencia sea válida.'
  },
  balance_adjustments: {
    name: 'Ajuste de Add-ons',
    list_title: 'Ajustes de Add-ons',
    success: 'Se ha modificado el saldo correctamente.',
    adjustment_limit_reached: 'Solo puede modificarse el saldo de un máximo de diez Add-ons a la vez',
    no_list_transactions_permission: 'Para visualizar ajustes de Add-ons, es necesario el permiso para "Listar transacciones"',
    errors: {
      generic: 'No se ha podido modificar el saldo.'
    }
  },
  transactions: {
    receipt: 'Comprobante',
    to_be_refunded_in: 'A devolver en: ',
    type: {
      PAYMENT: 'Pago',
      BOOKING: 'Reserva',
      refund: {
        PAYMENT: 'Devolución de pago',
        BOOKING: 'Devolución de reserva',
      }
    },
    error:{
      over_refund: 'Se ha sobrepasado la cantidad máxima reembolsable: {curr}/{max} {currencyName}.',
      receipt: 'Ha ocurrido un error durante la impresión del recibo'
    },
    success: {
      add_balance: 'Se ha añadido saldo correctamente.',
      top_up_balance: 'Saldo añadido'
    }
  },
  refund_requests: {
    status: {
      PENDING: 'Pendiente',
      ACCEPTED: 'Aceptada',
      REJECTED: 'Rechazada'
    },
    fields: {
      bank_account_number: 'Número de cuenta',
      created_at: 'Fecha de creación',
      created_since: 'Creado desde',
      created_until: 'Creado hasta',
      guest: 'Usuario',
      rejection_reason: 'Motivo de rechazo',
      resolved_at: 'Fecha de resolución',
      resolved_since: 'Resuelto desde',
      resolved_until: 'Resuelto hasta',
      swift: 'BIC / SWIFT',
      bank_account_image_file: 'Imagen de cuenta'
    },
    actions: {
      search_placeholder: 'Buscar por nombre completo, número de cuenta, BIC o SWIFT y correo electrónico',
      reject: 'Rechazar solicitud de reembolso',
      accept: 'Aceptar solicitud de reembolso'
    },
    errors: {
      accept: 'No se ha podido aceptar la solicitud de reembolso',
      reject: 'No se ha podido rechazar la solicitud de reembolso'
    },
    success: {
      accept: 'Solicitud aceptada correctamente',
      reject: 'Solicitud rechazada correctamente'
    },
    warnings: {
      reject: '¿Seguro que quieres rechazar esta solicitud de reembolso?',
      accept: '¿Seguro que quieres aceptar esta solicitud de reembolso?'
    }
  },
  roles: {
    custom_resource_names: {
      LIST_ZONES_BY_FACILITY: 'Listar por instalación',
      GET_GUESTS_BY_BOOKING: 'Listar huéspedes',
      GET_ROOMS_BY_BOOKING: 'Listar habitaciones',
      GET_PERIPHERALS_TOKEN: 'Solicitar token',
      LIST_RESOURCES: 'Listar recursos',
      GET_ENTITY_BY_ID_AND_SERVICE_ID: 'Detalles de entidad',
      SET_EXTERNAL_ID_FOR_ENTITY_BY_ID: 'Añadir ID externo',
      REMOVE_EXTERNAL_ID_FOR_ENTITY_BY_ID: 'Eliminar ID externo',
      REMOVE_EXTERNAL_ID_FOR_ENTITY_BY_ID_AND_SERVICE_ID: 'Eliminar ID externo por servicio',
      SET_EXTERNAL_ID_FOR_ENTITY_BY_ID_AND_SERVICE_ID: 'Añadir ID externo por servicio',
      TOGGLE_POSTPAID_ACCOUNT_BY_CURRENCY: 'Activar/Desactivar pospago',
      PUT_ACCOUNT_TO_BOOKING_AND_ROOM: 'Añadir cuenta para booking/habitación',
      REMOVE_ACCOUNT_TO_BOOKING_AND_ROOM: 'Quitar cuenta de booking/habitación',
      GET_DEFAULT_CURRENCY: 'Ver divisa predeterminada',
      PUT_DEFAULT_CURRENCY: 'Definir divisa predeterminada',
      GET_DEFAULT_EXCHANGE_RATE_FOR_CURRENCY: 'Ver valor de conversión por defecto',
      UPSERT_DEFAULT_EXCHANGE_RATE_FOR_CURRENCY: 'Editar valor de conversión por defecto',
      LIST_EXTENSIONS_BY_FACILITY: 'Listar por instalación',
      LIST_TRANSACTIONS_BY_REFERENCE: 'Listar transacciones por referencia',
      LIST_TRANSACTIONS_BY_ACCOUNT_IDS: 'Listar transacciones por cuentas',
      LIST_EXCHANGE_RATES_BY_CURRENCY: 'Listar valores de conversión por divisa',
      LIST_DEFAULT_EXTENSIONS: 'Listar extensiones predeterminadas',
      LIST_DIET_RESTRICTIONS_BY_PROFILE: 'Listar patrones dietéticos',
      ADD_DIET_RESTRICTION_TO_PROFILE: 'Añadir patrones dietéticos',
      REMOVE_DIET_RESTRICTION_FROM_PROFILE: 'Retirar patrones dietéticos',
      LIST_TRANSACTIONS_BY_ACCOUNT_ID: 'Listar transacciones de cuenta',
      LIST_TRANSACTION_FILES: 'Listar archivos',
      GET_TRANSACTION_FILE: 'Ver archivo',
      PUT_TRANSACTION_FILE: 'Añadir archivo',
      LIST_ACCOUNT_FILES: 'Listar archivos',
      GET_ACCOUNT_FILE: 'Ver archivo',
      PUT_ACCOUNT_FILE: 'Añadir archivo',
      LIST_LIMITS_BY_ACCOUNT_ID: 'Listar por cuenta',
      LIST_LIMITS_BY_GUEST_ID: 'Listar por huésped',
      LIST_BOOKING_FILES: 'Listar archivos',
      GET_BOOKING_FILE: 'Ver archivo',
      PUT_BOOKING_FILE: 'Añadir archivo',
      LIST_FACILITY_FILES: 'Listar archivos',
      GET_FACILITY_FILE: 'Ver archivo',
      PUT_FACILITY_FILE: 'Añadir archivo',
      LIST_SERVICES_BY_FACILITY: 'Listar por instalación',
      LIST_SERVICE_FILES: 'Listar archivos',
      GET_SERVICE_FILE: 'Ver archivo',
      PUT_SERVICE_FILE: 'Añadir archivo',
      GET_CONFIGURATION_FOR_SERVICE: 'Ver configuración',
      SET_CONFIGURATION_FOR_SERVICE: 'Modificar configuración',
      LIST_GUEST_FILES: 'Listar archivos',
      GET_GUEST_FILE: 'Ver archivo',
      PUT_GUEST_FILE: 'Añadir archivo',
      LIST_OPERATOR_FILES: 'Listar archivos',
      GET_OPERATOR_FILE: 'Ver archivo',
      PUT_OPERATOR_FILE: 'Añadir archivo',
      LIST_PROFILE_FILES: 'Listar archivos',
      GET_PROFILE_FILE: 'Ver archivo',
      PUT_PROFILE_FILE: 'Añadir archivo',
      LIST_ROOM_TYPE_FILES: 'Listar archivos',
      GET_ROOM_TYPE_FILE: 'Ver archivo',
      PUT_ROOM_TYPE_FILE: 'Añadir archivo',
      LIST_STATION_FILES: 'Listar archivos',
      GET_STATION_FILE: 'Ver archivo',
      PUT_STATION_FILE: 'Añadir archivo',
      EDIT_STOCK_IN_STOCK_PACKAGE: 'Editar stock en paquete',
      PERFORM_MATERIAL_LOAN: 'Prestar material',
      ACCESS_CONTROL: 'Control de acceso',
      IMPORT_STOCK: 'Importar stock',
      STATS_STOCK: 'Ver estadísticas',
      GET_ACCOUNT_ASSOCIATIONS: 'Ver asociaciones',
      GET_GUEST_KEY_ENCODING_DATA: 'Obtener datos de codificación de llaves para huésped',
      CREATE_SERVICE_TOKEN: 'Crear token para servicio',
      GET_ACCESS_AREA_LOGS: 'Ver logs',
      LIST_SERVICE_SCOPES: 'Listar alcances',
      GET_EMAIL_TEMPLATE_FILE: 'Obtener archivo',
      LIST_EMAIL_TEMPLATE_FILES: 'Listar archivos',
      GET_BOOKING_REGISTRATION_CARDS: 'Obtener por reserva',
      GET_FACILITY_REGISTRATION_CARD_STEPS_CONFIGURATION: 'Obtener pasos de configuración',
      GET_GUEST_REGISTRATION_CARDS: 'Obtener por huésped',
      POST_GUEST_REGISTRATION_CARD_CONTACT: 'Editar información de contacto de huésped',
      POST_GUEST_REGISTRATION_CARD_VERIFIED_DOCUMENT_DATA: 'Verificar documento de huésped',
      GET_GUEST_REGISTRATION_CARD_FILE: 'Obtener archivo',
      GET_BOOKING_SETTINGS: 'Ver configuración (app)',
      PUT_BOOKING_SETTINGS: 'Crear/editar configuración (app)',
      PUT_BOOKING_SETTINGS_PASSWORD: 'Cambiar contraseña (app)',
      REVOKE_BOOKING_TOKEN: 'Cerrar sesión (app)',
      SEND_BOOKING_CREDENTIALS_MESSAGE: 'Enviar email',
      CREATE_BOOKING_TOKEN: 'Crear token',
      LIST_SERVICE_PUSH_NOTIFICATIONS: 'Listar notificaciones push',
      SUBSCRIBE_SERVICE_TO_PUSH_NOTIFICATION_EVENT: 'Suscribir a notificaciones push',
      UNSUBSCRIBE_SERVICE_FROM_PUSH_NOTIFICATION_EVENT: 'Desuscribir de notificaciones push',
      LIST_WORKING_HOURS_BY_ACTIVITY: 'Listar horas de trabajo',
      GET_ACTIVITY_WORKING_HOURS: 'Ver horas de trabajo',
      UPSERT_ACTIVITY_WORKING_HOUR: 'Crear/editar horas de trabajo',
      DELETE_ACTIVITY_WORKING_HOURS: 'Eliminar horas de trabajo',
      LIST_ACTIVITY_EXCLUDED_SLOTS: 'Listar franjas bloqueadas',
      UPSERT_ACTIVITY_EXCLUDED_SLOT: 'Bloquear franja',
      DELETE_ACTIVITY_EXCLUDED_SLOT: 'Desbloquear franja',
      POST_ACTIVITY_RESERVATION_REJECT: 'Rechazar',
      POST_ACTIVITY_RESERVATION_ACCEPT: 'Aceptar',
      POST_ACTIVITY_RESERVATION_CANCEL: 'Cancelar',
      POST_ACTIVITY_RESERVATION_ABSENT: 'Ausentar',
      POST_ACTIVITY_RESERVATION_ATTEND: 'Asistir',
      GET_CATALOG_ASSOCIATIONS: 'Ver entidades relacionadas',
      GET_ORDER_TRANSACTIONS: 'Ver transacciones',
      POST_ORDER: 'Crear',
      GET_PENDING_ORDER: 'Ver pedido pendiente',
      REFUND_ORDER: 'Reembolsar',
      GET_ACTIVITY_RESERVATION_ORDER: 'Ver pedido',
      GET_ACTIVITY_RESERVATION_TICKET: 'Ver ticket',
      GET_REPORTS: 'Listar',
      GET_OWN_REPORTS: 'Ver mis informes',
      GET_REPORTS_FILE: 'Obtener archivo de informes',
      LIST_REPORT_FILES: 'Obtener archivos de informes',
      POST_ACCESS_CONTROL_REPORT: 'Subir informe de control de acceso',
      POST_ASSIGNED_WRISTBANDS_REPORT: 'Subir informe de bandas asignadas',
      POST_BALANCE_ON_CHECKOUT_REPORT: 'Subir informe de balance tras checkout',
      POST_TRANSACTIONS_REPORT: 'Subir informe de transacciones',
      POST_MATERIAL_LOANS_REPORT: 'Subir informe de préstamo de material',
      POST_STOCK_MOVEMENTS_REPORT: 'Subir informe de movimiento de stock',
      LIST_RELATED_TRANSACTIONS: 'Listar transacciones relacionadas',
      GET_ACTIVITY_FILE: 'Ver archivo',
      POST_WEBAPP_USERS_PASSWORD: 'Establecer contraseña',
      GET_BOOKING_RELATED_ACCOUNT: 'Obtener cuenta asociada',
      GET_ACTIVITY_RESERVATION_METHODS: 'Listar métodos de reserva',
      UPSERT_RESERVATION_METHOD: 'Crear/editar métodos de reserva',
      DELETE_RESERVATION_METHOD_ATTENDEE_TYPE: 'Eliminar tipo de asistente',
      GET_RESERVATION_METHOD_ATTENDEE_TYPE: 'Obtener tipo de asistente',
      UPSERT_RESERVATION_METHOD_ATTENDEE_TYPES: 'Crear/editar tipos de asistente',
      LIST_RESERVATION_METHOD_ATTENDEE_TYPES: 'Listar tipos de asistente',
      LIST_ORDER_REFUNDS_BY_ORDER_ID: 'Listar reembolsos por pedido'
    },
    groups: {
      ACTIONS: 'Acciones',
      BOOKINGS: 'Reservas',
      CURRENCIES: 'Divisas',
      FACILITIES: 'Instalaciones',
      GUESTS: 'Huéspedes',
      OPERATORS: 'Operarios',
      PERIPHERALS: 'Periféricos',
      ROLES: 'Permisos',
      OTHER: 'Otros',
      STATIONS: 'Estaciones',
      TRANSACTIONS: 'Transacciones',
      ZONES: 'Zonas',
      ACCESS_AREAS: 'Áreas de acceso',
      PRICING_GROUPS: 'Configuraciones de precios',
      ROOMS: 'Habitaciones',
      PROFILES: 'Perfiles',
      ROOM_TYPES: 'Tipos de habitación',
      ALL: 'Seleccionar todos los permisos permanentemente',
      SERVICES: 'Servicios',
      GUEST_TYPES: 'Tipos de huésped',
      PAYMENT_METHODS: 'Métodos de pago',
      DIET_RESTRICTIONS: 'Patrones dietéticos',
      ACCOUNTS: 'Cuentas',
      DEFAULTS: 'Opciones predeterminadas',
      EXCHANGE_RATES: 'Valor de conversión de divisas',
      EXTENSIONS: 'Extensiones',
      LIMIT_TEMPLATES: 'Plantillas de límite de consumo',
      LIMITS: 'Límites de consumo',
      GUEST_CATEGORIES: 'Categorías de huésped',
      PROFILE_TYPES: 'Tipos de perfil',
      PROFILE_CATEGORIES: 'Categorías de perfil',
      BOOKING_TYPES: 'Tipos de reservas',
      STOCK_PACKAGES: 'Paquetes de stock',
      STOCK_TYPES: 'Tipos de stock',
      STOCK: 'Stock',
      LOGS: 'Logs',
      MATERIAL_LOANS: 'Préstamos de material',
      TRANSACTION_REQUESTS: 'Solicitud de transacciones',
      COMMUNICATION_CHANNELS: 'Canales de comunicación',
      EMAIL_TEMPLATES: 'Plantillas de correo electrónico',
      REGISTRATION_CARDS: 'Tarjeta de registro',
      ACTIVITIES: 'Actividades',
      ACTIVITY_CATEGORIES: 'Categorías de actividades',
      ACTIVITY_RESERVATIONS: 'Reservas de actividades',
      WEBAPP_USERS: 'Usuarios de la webapp',
      CATALOG_PRODUCTS: 'Productos de catálogos',
      CATALOG_CATEGORIES: 'Categorías de catálogos',
      CATALOGS: 'Catálogos',
      TAXES: 'Impuestos',
      ORDERS: 'Pedidos',
      REPORTS: 'Informes',
      TAX_GROUPS: 'Grupos de impuestos',
      ACTIVITY_RESERVATION_METHODS: 'Métodos de reserva'
    },
    resources: {
      UPSERT: 'Crear/Editar',
      CREATE: 'Crear',
      LIST: 'Listar',
      PAGINATE: 'Listar',
      REMOVE: 'Eliminar',
      SET: 'Definir',
      REVOKE: 'Invalidar sesiones',
      GET: 'Detalles',
      EDIT: 'Editar',
      ADD: 'Crear',
      TOGGLE: 'Activar/Desactivar',
      DELETE: 'Borrar',
      SYNC: 'Sincronizar',
      UPDATE: 'Actualizar',
      ASSIGN: 'Asignar',
      PUT: 'Añadir',
      POST: 'Subir', // TODO: mejorar traducción
      RESOLVE: 'Resolver',
      RESERVE: 'Reservar',
      SEND: 'Enviar',
      TEST: 'Probar'
    }
  },
  error: {
    generic_retry: 'Ha habido un error. Vuelve a intentarlo.',
    not_found: 'No se ha encontrado',
    no_configuration: 'Configura la aplicación en',
    server_disconnected: 'Servidor desconectado',
    no_peripheral: 'No hay periféricos',
    server_error: 'Error en el servidor',
    conflict: {
      DISABLED_ACCOUNT: 'Cuenta deshabilitada',
      LIMIT_EXCEEDED: 'Esta acción sobrepasaría el límite de consumo de la cuenta',
      NOT_ENOUGH_BALANCE: 'Saldo insuficiente',
      PAYMENT_GATEWAY_COMMUNICATION_ERROR: 'Error de comunicación con la pasarela de pago',
      TRANSACTION_ALREADY_EXISTS: 'La transacción ya existe',
      NO_OPEN_SHIFT: 'Se requiere turno abierto'
    },
    no_open_shift_supervisor: 'Se requiere turno abierto del supervisor',
    auth: {
      long_inactivity_operator: 'El operario ha estado inactivo demasiado tiempo. Contacta con un administrador',
      disabled_operator: 'Operario inactivo',
      unauthorized: 'No autorizado'
    }
  },
  default_content: {
    warning: 'Atención',
    no_name: 'Sin nombre',
    disabled_header: 'Permiso necesario',
    disabled_header_permission: 'Se requiere permiso para "{resource}"'
  },
  peripherals: {
    warnings: {
      disconnected_peripheral_service: 'Controlador de periféricos desconectado',
      printer_not_configured: 'No se ha configurado una impresora',
      reader_not_configured: 'No se ha configurado un lector'
    },
    error: {
      socket_connection: 'Error de conexión con el periférico. Comprueba la configuración'
    },
    printer: {
      success: 'Se ha imprimido correctamente'
    }
  },
  pagination: {
    items_per_page: 'Elementos por página:',
    items_per_page_all: 'Todos',
    page_text: '{0}-{1} de {2}',
    no_data: 'No hay elementos',
    items: 'Elementos',
    many: 'muchos'
  }
}
