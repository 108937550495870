export class Address {
  constructor (params = {}) {
    this.line1 = params.line1
    this.line2 = params.line2
    this.city = params.city
    this.postalCode = params.postal_code
    this.region = params.region
    this.countryCode = params.country_code
  }
}
