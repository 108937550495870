import camelcaseKeys from 'camelcase-keys'
class PoSConfiguration {
  constructor (params = {}) {
    const { cashPaymentMethodId, creditCardPaymentMethodId, manualPaymentMethodId, exchangeCurrencies } = camelcaseKeys(params, { deep: true })
    this.paymentMethodIds = [cashPaymentMethodId, creditCardPaymentMethodId]
    this.cashPaymentMethodId = cashPaymentMethodId
    this.manualPaymentMethodId = manualPaymentMethodId
    this.creditCardPaymentMethodId = creditCardPaymentMethodId
    this.exchangeCurrencies = exchangeCurrencies || []
    this.foreignCurrencyExchangeRate = null
  }
}
class GlobalConfiguration {
  constructor (params = {}) {
    var { currencyTypesId, guestTypesId, serviceTypesId, hotelFacilityId, parkFacilityId, pricingGroupTypesId, passes } = camelcaseKeys(params, { deep: true, stopPaths: ['currency_types_id', 'guest_types_id', 'pricing_group_types_id', 'service_types_id'] })
    this.serviceTypesId = serviceTypesId || {}
    this.currencyTypesId = currencyTypesId || {}
    this.currencyIds = currencyTypesId ? Object.keys(currencyTypesId).map((k) => {
      return currencyTypesId[k]
    }) : []
    this.currencies = []
    this.localCurrency = null
    this.foreignCurrency = null
    this.pricingGroupTypesId = pricingGroupTypesId || {}
    this.pricingGroups = []
    this.parkFacilityId = parkFacilityId || null
    this.hotelFacilityId = hotelFacilityId || null
    this.passes = passes || []
    this.guestTypesId = guestTypesId || {}
  }
}
export class Configuration {
  constructor (params = {}) {
    const { configuration, posConfiguration } = camelcaseKeys(params)
    this.posConfiguration = new PoSConfiguration(posConfiguration)
    this.configuration = new GlobalConfiguration(configuration)
  }
}
