import camelCaseKeys from 'camelcase-keys'

export class RefundRequest {
  constructor (params = {}) {
    const { id, swift, email, status, guestId, fullName, createdAt, resolvedAt, rejectionReason, bankAccountNumber, bankAccountImageFileId, amount } = camelCaseKeys(params)
    this.id = id || null
    this.swift = swift || null
    this.email = email || null
    this.status = status || null
    this.guestId = guestId || null
    this.fullName = fullName || null
    this.createdAt = createdAt || null
    this.resolvedAt = resolvedAt || null
    this.rejectionReason = rejectionReason || null
    this.bankAccountNumber = bankAccountNumber || null
    this.bankAccountImageFileId = bankAccountImageFileId || null
    this.amount = typeof amount === 'number' ? amount : null
  }
}
