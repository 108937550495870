import { ExternalId } from './ExternalId'
export class Operator {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.active = params.active ? params.active : false
    this.name = params.name ? params.name : null
    this.username = params.username ? params.username : null
    this.email = params.email ? params.email : null
    this.role = params.role ? params.role : params.role_id
    this.roleId = params.role_id ? params.role_id : null
    this.externalIds = params.external_ids ? params.external_ids.map((e) => new ExternalId(e)) : []
    this.managedByFacility = params.managedByFacility ? params.managedByFacility : null
    this.managedByFacilityId = params.managed_by_facility_id ? params.managed_by_facility_id : null
    this.stationIds = params.station_ids ? params.station_ids : null
  }
}
