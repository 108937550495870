export default {
  fields: {
    id: 'ID',
    username_or_mail: 'Username or e-mail',
    password: 'Password',
    facility: 'Facility',
    station: 'Station',
    reader_peripheral: 'Reader',
    printer_peripheral: 'Printer',
    operator: 'Operator',
    device: 'Device',
    status: 'Status',
    name: 'Name',
    last_name: 'Last name',
    children: 'Nº of children',
    reference: 'Reference',
    created: 'Created',
    service: 'Service',
    total: 'Total',
    concept: 'Concept',
    original_amount: 'Original amount',
    refunded_amount: 'Refunded amount',
    payment_method: 'Payment method',
    ticket_reference: 'Ticket reference',
    order_reference: 'Order reference',
    pass: 'Ticket type',
    currency: 'Currency',
    change: 'Change',
    cash: 'Cash',
    amount: 'Amount',
    price: 'Price',
    items: 'Items',
    type: 'Type',
    email: 'Email',
    full_name: 'Full name',
    reject: 'Reject'
  },
  drawer: {
    current_session: 'Current session',
    current_shift: 'Current shift'
  },
  actions: {
    sign_in: 'Sign in',
    sign_out: 'Sign out',
    loading: 'Loading',
    menu: 'Menu',
    cancel: 'Cancel',
    continue: 'Continue',
    confirm: 'Confirm',
    accept: 'Accept',
    reject: 'Reject',
    close: 'Close',
    retry: 'Retry',
    info_form: '{section} details',
    clear: 'Clear',
    info: 'Info',
    reset_purchase: 'Reset purchase',
    refund: 'Refund',
    complete_transaction: 'Complete transaction',
    validate: 'Validate',
    locate: 'Locate',
    cancel_payment: 'Cancel payment',
    reset_adjustments: 'Reset adjustments'
  },
  sections: {
    settings: 'Settings',
    guest: 'Ticket sales',
    ticket: 'Ticket sales',
    shifts: 'Shift recounts',
    ticket_order: 'Ticket sale',
    ticket_validation: 'Ticket validation',
    refund: 'Order refund',
    refund_requests: 'Refund requests',
    balance_adjustment: 'Add-on adjustment',
    package_order: 'Add-on sales'
  },
  ticket_order: {
    visitor: 'Visitor {number}',
    pricing_group: 'Pricing group',
    actions: {
      add_visitor: 'Add visitor',
      proceed_with_purchase: 'Proceed with purchase',
      edit_data: 'Edit data',
      clear: 'Clear order'
    },
    confirm: {
      title: 'Complete the payment process',
      text: `Only mark the payment as completed once you've collected payment from the visitor.`,
      confirm: 'Confirm completed payment',
      cancel: 'Cancel payment'
    },
    success: 'Successfully completed purchase',
    error: {
      receipt: 'There was an error while printing the receipt',
      payment: 'There was an error during payment and the order could not be completed'
    },
    loading: {
      complete: 'Requesting purchase',
      ticket: 'Generating ticket',
      print: 'Printing ticket'
    }
  },
  ticket_validation: {
    missing_booking: 'Ticket not found. Check whether the reference is valid.'
  },
  summary: {
    title: 'Summary',
    actions: {
      continue: 'Finish purchase',
      cancel: 'Cancel purchase',
      proceed_with: 'Proceed with'
    },
    total: 'Total',
    empty_order: 'Add items to your order',
    empty_adjustments: 'No adjustments'
  },
  shifts: {
    required_shift: {
      title: 'Restricted action',
      open_shift_required: 'Open shift is required',
    },
    fields: {
      opened: 'Opened',
      opened_by: 'Opened by',
      opened_at: 'Opened at',
      opened_since: 'Opened since',
      opened_until: 'Opened until',
      closed: 'Closed',
      closed_by: 'Closed by',
      closed_since: 'Closed since',
      closed_until: 'Closed until',
      cash: 'Cash',
      card: 'Card'
    },
    actions: {
      open: 'Open shift',
      close: 'Close shift',
      close_operator: 'Close operator shift',
      close_printer: 'Close device shift',
      print: 'Print'
    },
    errors: {
      device_has_active_shift: 'This device has an active shift for a different operator. End the previous shift to start a new one.',
      operator_has_active_shift: 'The current operator has an active shift at {device}. End the previous shift to start a new one.'
    },
    warnings: {
      long_shift: 'Shift over 12h long',
      required: 'Operator turn is required'
    },
    open_shift: {
      title: 'Opening shift for {operator} at {printer}',
      success: 'Successfully opened shift',
      error: {
        fallback: 'Could not open shift',
        PRINTER_HAS_OPEN_SHIFT: 'There is already an open turn for configured printer'
      }
    },
    close_shift: {
      title: 'Closed shift for @:shifts.close_shift.for_operator @:shifts.close_shift.at_device',
      for_operator: 'for {operator}',
      at_device: 'at {device}',
      initial: 'Initial',
      final: 'Final',
      difference: 'Difference',
      error: {
        ticket: 'There was an error printing the receipt',
        SHIFT_IS_ALREADY_CLOSED: 'Shift already closed'
      },
      getting_ticket: 'Generating receipt',
      success: 'Successfully closed shift'
    },
    status: {
      OPEN: 'Open',
      CLOSED: 'Closed'
    },
    details: 'Shift details',
    opened_at_date: 'Opened {date}',
    unknown_printer: 'another device',
    loading: 'Loading turn'
  },
  tickets: {
    name: 'Ticket',
    title: 'Ticket information'
  },
  temp_login: {
    title: 'Input supervisor credentials'
  },
  reader: {
    read_identifier: 'Read wristband',
    bring_wristband_closer: 'Bring the wirstband close to the reader',
    attempting_connection: 'Waiting for reader',
    assigning_identifier: 'Assigning brazalete',
    error: {
      socket_connection: 'Peripheral connection error. Check the connection status.',
      FALLBACK: {
        title: 'Communication error',
        description: 'Contact an administrator if the issue persists'
      },
      BUSY: {
        title: 'Busy reader'
      },
      TIMEOUT: {
        title: 'Expired action'
      },
      ACTION_NOT_IMPLEMENTED: {
        title: 'Action not implemented',
        description: 'Check for updates on the peripheral controller'
      },
      KEY_SERVER_ERROR: {
        title: 'Key server error'
      },
      TAG_TYPE_NOT_SUPPORTED: {
        title: 'Invalid identifier type'
      },
      TAG_ENCODING_ERROR: {
        title: 'Tag encoding error'
      },
      ENTITY_DOES_NOT_EXIST: {
        title: 'This identifier has no associated guest'
      },
      NO_MATCH: {
        title: 'Disconnected reader'
      },
      auth_error: {
        title: 'Authentication error',
        description: `Check the peripheral controller's configuration`
      },
      INVALID_ACTION: {
        title: 'Invalid action',
        description: 'Check for updates on the peripheral controller'
      }
    },
    success: {
      key_generated: 'Wirstband successfully assigned',
      key_cancelled: 'Wristband successfully unassigned'
    }
  },
  bookings: {
    valid_on: 'Valid on',
    valid_from: 'Valid from',
    valid_to: 'to',
    visitors: 'Visitors',
    purchase_packages: 'Purchase Add-ons',
    details: 'Details',
    cancelled: 'Cancelled',
    assigned_wristbands: 'Assigned wristbands',
    park_visitor: 'Park visitor',
    hotel_guest: 'Hotel guest',
    ticket_purchase: 'Ticket purchase',
    online_purchase: 'Online purchase'
  },
  orders: {
    receipt: 'Receipt',
    refunded: 'Refunded',
    order_refund: 'Order refund',
    confirm_refund: 'The total amout of this order will be refunded.',
    loading: {
      complete: 'Requesting refund',
      ticket: 'Generating receipt',
      print: 'Printing receipt'
    },
    error: {
      refund: 'An error ocurred during order refund',
      receipt: 'Refund ticket printing failed'
    },
    success: {
      refund: 'Order refunded successfully'
    }
  },
  payment_methods: {
    room_charge: 'Room charge'
  },
  guests: {
    info_title: 'Visitor information',
    assign_wristband: 'Assign wristband',
    non_assignable_wristband_hotel_guest: 'Guests must be assigned an identifier at the hotel reception.',
    non_unassignable_wristband_hotel_guest: 'Guest wirsbands can only be unassigned at the hotel reception.',
    unassign_wirstband: 'Unassign wirstband',
    disabled: 'Disabled',
    disabled_topup_balance_hotel: 'Guests can only top up balance in the hotel',
    visitor_disabled: 'Visitor disabled',
    account_required: 'Account required',
    show_currencies_with_balance: 'Show currencies with no balance',
    transactions: 'Transactions',
    package_purchases: 'Add-on purchases',
    no_list_transactions_permission: 'To display visitor transactions, "List transactions" permission is required',
    actions: {
      disable_all: 'Cancel ticket',
      disable_all_unavailable_guests: 'Tickets with guests may only be disabled at the hotel',
      disable_all_unavailable_ticketing: 'Tickets purchased online can only be disabled at the online ticketing platform'
    },
    type: {
      INFANT: 'Infant',
      CHILD: 'Child',
      TEENAGER: 'Teenager',
      ADULT: 'Adult'
    },
    confirm: {
      unassign_wirstband: 'Are you sure that you want to unassign this visitor\'s wirstband?',
      disable_all: 'Are you sure that you want to disable all visitors in this reservation? Disabled guests won\'t be able to buy Add-ons.'
    },
    errors: {
      unassign_wirstband: 'Wirstband could not be unassigned'
    }
  },
  accounts: {
    amount: 'Amount',
    balance: 'Balance',
    actions: {
      top_up_balance: 'Top up balance',
      confirm_completed_payment: 'Confirm completed payment'
    },
    top_up_warning: 'Only mark the payment as completed once you\'ve collected payment from the visitor.'
  },
  packages: {
    name: 'Add-on',
    package_name: 'Package name',
    top_up_packages: 'Top up Add-ons',
    search: 'Add-on name',    
    daily_limit_exceed: 'Adding Add-ons would exceed the maximum amount per day',
    account_limit_exceed: 'Adding Add-ons would exceed the maximum amount by account',
    fields: {
      current: 'Current',
      current_daily: 'Available today',
      max: 'Max',
      max_daily: 'Limit per day'
    },
    warnings: {
      account_limit_exceeding_packages: 'The account limit will be exceeded for the following Add-ons:',
      daily_limit_exceeding_packages: 'The daily limit will be exceeded for the following Add-ons:'
    },
    success: {
      purchase_completed: 'Successfully completed purchase'
    }
  },
  package_order: {
    loading: {
      complete: 'Requesting purchase',
      ticket: 'Generating ticket',
      print: 'Printing ticket'
    }
  },
  order_refund: {
    missing_order: 'Order not found. Check wether the reference is valid.'
  },
  balance_adjustments: {
    name: 'Add-on adjustment',
    list_title: 'Add-on adjustments',
    success: 'Balance modifications applied successfully.',
    adjustment_limit_reached: 'Only 10 Add-ons can be modified at once.',
    no_list_transactions_permission: 'To display Add-on adjustments, permission "List transactions" is required',
    errors: {
      generic: 'Could not modify balance.'
    }
  },
  transactions: {
    receipt: 'Receipt',
    to_be_refunded_in: 'To be refunded in: ',
    type: {
      PAYMENT: 'Payment',
      BOOKING: 'Ticket',
      refund: {
        PAYMENT: 'Payment refund',
        BOOKING: 'Ticket refund',
      }
    },
    error: {
      over_refund: 'Total refundable amount exceeded: {curr}/{max} {currencyName}.',
      receipt: 'There was an error while printing the receipt'
    },
    success: {
      add_balance: 'Balance added successfully.',
      top_up_balance: 'Balance added'
    }
  },
  refund_requests: {
    status: {
      PENDING: 'Pending',
      ACCEPTED: 'Accepted',
      REJECTED: 'Rejected'
    },
    fields: {
      bank_account_number: 'Bank account number',
      created_at: 'Created at',
      created_since: 'Created since',
      created_until: 'Creado until',
      guest: 'User',
      rejection_reason: 'Rejection reason',
      resolved_at: 'Resolved at',
      resolved_since: 'Resolved since',
      resolved_until: 'Resolved until',
      swift: 'BIC / SWIFT',
      bank_account_image_file: 'Account image'
    },
    actions: {
      search_placeholder: 'Search by full name, bank account number, BIC or SWIFT and email',
      reject: 'Reject refund request',
      accept: 'Accept refund request'
    },
    errors: {
      accept: 'Could not accept the refund request',
      reject: 'Could not reject the refund request'
    },
    success: {
      accept: 'Request accepted successfully',
      reject: 'Request rejected successfully'
    },
    warnings: {
      reject: 'Are you sure you want to reject this refund request?',
      accept: 'Are you sure you want to accept this refund request?'
    }
  },
  roles: {
    custom_resource_names: {
      LIST_ZONES_BY_FACILITY: 'List by facility',
      GET_GUESTS_BY_BOOKING: 'List guests',
      GET_ROOMS_BY_BOOKING: 'List rooms',
      GET_PERIPHERALS_TOKEN: 'Get token',
      LIST_RESOURCES: 'List resources',
      GET_ENTITY_BY_ID_AND_SERVICE_ID: 'Entity details',
      SET_EXTERNAL_ID_FOR_ENTITY_BY_ID: 'Add external ID',
      REMOVE_EXTERNAL_ID_FOR_ENTITY_BY_ID: 'Remove external ID',
      REMOVE_EXTERNAL_ID_FOR_ENTITY_BY_ID_AND_SERVICE_ID: 'Remove external ID by service',
      SET_EXTERNAL_ID_FOR_ENTITY_BY_ID_AND_SERVICE_ID: 'Add external ID by service',
      TOGGLE_POSTPAID_ACCOUNT_BY_CURRENCY: 'Activate/Deactivate postpaid',
      PUT_ACCOUNT_TO_BOOKING_AND_ROOM: 'Add account for booking/room',
      REMOVE_ACCOUNT_TO_BOOKING_AND_ROOM: 'Remove account from booking/room',
      GET_DEFAULT_CURRENCY: 'Get default currency',
      PUT_DEFAULT_CURRENCY: 'Set default currency',
      GET_DEFAULT_EXCHANGE_RATE_FOR_CURRENCY: 'Get default exchange rate for currency',
      UPSERT_DEFAULT_EXCHANGE_RATE_FOR_CURRENCY: 'Set default exchange rate for currency',
      LIST_EXTENSIONS_BY_FACILITY: 'List by facility',
      LIST_TRANSACTIONS_BY_REFERENCE: 'List transactions by reference',
      LIST_TRANSACTIONS_BY_ACCOUNT_IDS: 'List transactions by accounts',
      LIST_EXCHANGE_RATES_BY_CURRENCY: 'List exchange rates by currency',
      LIST_DEFAULT_EXTENSIONS: 'List default extensions',
      LIST_DIET_RESTRICTIONS_BY_PROFILE: 'List diet restrictions',
      ADD_DIET_RESTRICTION_TO_PROFILE: 'Add diet restrictions',
      REMOVE_DIET_RESTRICTION_FROM_PROFILE: 'Remove diet restrictions',
      LIST_TRANSACTIONS_BY_ACCOUNT_ID: 'List account transactions',
      LIST_TRANSACTION_FILES: 'List files',
      GET_TRANSACTION_FILE: 'File details',
      PUT_TRANSACTION_FILE: 'Add files',
      LIST_ACCOUNT_FILES: 'List files',
      GET_ACCOUNT_FILE: 'File details',
      PUT_ACCOUNT_FILE: 'Add files',
      LIST_LIMITS_BY_ACCOUNT_ID: 'List by account',
      LIST_LIMITS_BY_GUEST_ID: 'List by guest',
      LIST_BOOKING_FILES: 'List files',
      GET_BOOKING_FILE: 'File details',
      PUT_BOOKING_FILE: 'Add files',
      LIST_FACILITY_FILES: 'List files',
      GET_FACILITY_FILE: 'File details',
      PUT_FACILITY_FILE: 'Add files',
      LIST_SERVICES_BY_FACILITY: 'List by facility',
      LIST_SERVICE_FILES: 'List files',
      GET_SERVICE_FILE: 'File details',
      PUT_SERVICE_FILE: 'Add files',
      GET_CONFIGURATION_FOR_SERVICE: 'View configuration',
      SET_CONFIGURATION_FOR_SERVICE: 'Edit configuration',
      LIST_GUEST_FILES: 'List files',
      GET_GUEST_FILE: 'File details',
      PUT_GUEST_FILE: 'Add files',
      LIST_OPERATOR_FILES: 'List files',
      GET_OPERATOR_FILE: 'File details',
      PUT_OPERATOR_FILE: 'Add files',
      LIST_PROFILE_FILES: 'List files',
      GET_PROFILE_FILE: 'File details',
      PUT_PROFILE_FILE: 'Add files',
      LIST_ROOM_TYPE_FILES: 'List files',
      GET_ROOM_TYPE_FILE: 'File details',
      PUT_ROOM_TYPE_FILE: 'Add files',
      LIST_STATION_FILES: 'List files',
      GET_STATION_FILE: 'File details',
      PUT_STATION_FILE: 'Add files',
      EDIT_STOCK_IN_STOCK_PACKAGE: 'Edit stock in package',
      PERFORM_MATERIAL_LOAN: 'Loan material',
      ACCESS_CONTROL: 'Access control',
      IMPORT_STOCK: 'Import stock',
      STATS_STOCK: 'See stats',
      GET_ACCOUNT_ASSOCIATIONS: 'See associations',
      GET_GUEST_KEY_ENCODING_DATA: 'Get guest key encoding data',
      CREATE_SERVICE_TOKEN: 'Create service token',
      GET_ACCESS_AREA_LOGS: 'Get logs',
      LIST_SERVICE_SCOPES: 'List scopes',
      GET_EMAIL_TEMPLATE_FILE: 'Get file',
      LIST_EMAIL_TEMPLATE_FILES: 'List files',
      GET_BOOKING_REGISTRATION_CARDS: 'Get by booking',
      GET_FACILITY_REGISTRATION_CARD_STEPS_CONFIGURATION: 'Get configuration steps',
      GET_GUEST_REGISTRATION_CARDS: 'Get by guest',
      POST_GUEST_REGISTRATION_CARD_CONTACT: 'Put contact data',
      POST_GUEST_REGISTRATION_CARD_VERIFIED_DOCUMENT_DATA: 'Verify guest document',
      GET_GUEST_REGISTRATION_CARD_FILE: 'Get file',
      GET_BOOKING_SETTINGS: 'Get configuration (app)',
      PUT_BOOKING_SETTINGS: 'Create/Edit configuration (app)',
      PUT_BOOKING_SETTINGS_PASSWORD: 'Edit password (app)',
      REVOKE_BOOKING_TOKEN: 'Expire sessions (app)',
      SEND_BOOKING_CREDENTIALS_MESSAGE: 'Send email',
      CREATE_BOOKING_TOKEN: 'Create token',
      LIST_SERVICE_PUSH_NOTIFICATIONS: 'List push notifications',
      SUBSCRIBE_SERVICE_TO_PUSH_NOTIFICATION_EVENT: 'Suscribe to push notifications',
      UNSUBSCRIBE_SERVICE_FROM_PUSH_NOTIFICATION_EVENT: 'Unsubscribe from push notifications',
      LIST_WORKING_HOURS_BY_ACTIVITY: 'List working hours',
      GET_ACTIVITY_WORKING_HOURS: 'Get working hour info',
      UPSERT_ACTIVITY_WORKING_HOUR: 'Create/edit working hour',
      DELETE_ACTIVITY_WORKING_HOURS: 'Delete working hour',
      LIST_ACTIVITY_EXCLUDED_SLOTS: 'List blocked slots',
      UPSERT_ACTIVITY_EXCLUDED_SLOT: 'Block slot',
      DELETE_ACTIVITY_EXCLUDED_SLOT: 'Unblock slot',
      POST_ACTIVITY_RESERVATION_REJECT: 'Reject',
      POST_ACTIVITY_RESERVATION_ACCEPT: 'Accept',
      POST_ACTIVITY_RESERVATION_CANCEL: 'Cancel',
      POST_ACTIVITY_RESERVATION_ABSENT: 'Absent',
      POST_ACTIVITY_RESERVATION_ATTEND: 'Attend',
      GET_CATALOG_ASSOCIATIONS: 'Get related entities',
      POST_ORDER: 'Post',
      GET_ORDER_TRANSACTIONS: 'Get transactions',
      REFUND_ORDER: 'Refund',
      GET_PENDING_ORDER: 'Get pending order',
      GET_ACTIVITY_RESERVATION_ORDER: 'Get order',
      GET_ACTIVITY_RESERVATION_TICKET: 'Get ticket',
      GET_REPORTS: 'List reports',
      GET_OWN_REPORTS: 'Get own reports',
      GET_REPORTS_FILE: 'Get reports file',
      LIST_REPORT_FILE: 'List reports file',
      POST_ACCESS_CONTROL_REPORT: 'Post access control report',
      POST_ASSIGNED_WRISTBANDS_REPORT: 'Post assigned wristbands report',
      POST_BALANCE_ON_CHECKOUT_REPORT: 'Post balance on checkout report',
      POST_TRANSACTIONS_REPORT: 'Post transactions report',
      POST_MATERIAL_LOANS_REPORT: 'Post material loans report',
      POST_STOCK_MOVEMENTS_REPORT: 'Post stock movements report',
      LIST_RELATED_TRANSACTIONS: 'List related transactions',
      GET_ACTIVITY_FILE: 'Get file',
      POST_WEBAPP_USERS_PASSWORD: 'Set password',
      GET_BOOKING_RELATED_ACCOUNT: 'Get related account',
      GET_ACTIVITY_RESERVATION_METHODS: 'List reservation methods',
      UPSERT_RESERVATION_METHOD: 'Upsert reservation methods',
      DELETE_RESERVATION_METHOD_ATTENDEE_TYPE: 'Delete attendee type',
      GET_RESERVATION_METHOD_ATTENDEE_TYPE: 'Get attendee type',
      UPSERT_RESERVATION_METHOD_ATTENDEE_TYPES: 'Upsert attendee types',
      LIST_RESERVATION_METHOD_ATTENDEE_TYPES: 'List attendee types',
      LIST_ORDER_REFUNDS_BY_ORDER_ID: 'List refunds by order'
    },
    groups: {
      ACTIONS: 'Actions',
      BOOKINGS: 'Bookings',
      CURRENCIES: 'Currencies',
      FACILITIES: 'Facilities',
      GUESTS: 'Guests',
      OPERATORS: 'Operators',
      PERIPHERALS: 'Peripherals',
      ROLES: 'Roles',
      OTHER: 'Others',
      STATIONS: 'Stations',
      TRANSACTIONS: 'Transactions',
      ZONES: 'Zones',
      ACCESS_AREAS: 'Access areas',
      PRICING_GROUPS: 'Pricing setups',
      ROOMS: 'Rooms',
      PROFILES: 'Profiles',
      ROOM_TYPES: 'Room types',
      ALL: 'Permanently set all resources',
      SERVICES: 'Services',
      GUEST_TYPES: 'Guest types',
      PAYMENT_METHODS: 'Payment methods',
      DIET_RESTRICTIONS: 'Diet restrictions',
      ACCOUNTS: 'Accounts',
      DEFAULTS: 'Default options',
      EXCHANGE_RATES: 'Currency exchange rates',
      EXTENSIONS: 'Extensions',
      LIMIT_TEMPLATES: 'Consumption limit templates',
      LIMITS: 'Consumption limits',
      GUEST_CATEGORIES: 'Guest categories',
      PROFILE_TYPES: 'Profile types',
      PROFILE_CATEGORIES: 'Profile categories',
      BOOKING_TYPES: 'Booking types',
      STOCK_PACKAGES: 'Stock packages',
      STOCK_TYPES: 'Stock types',
      STOCK: 'Stock',
      LOGS: 'Logs',
      MATERIAL_LOANS: 'Material loans',
      TRANSACTION_REQUESTS: 'Transaction requests',
      COMMUNICATION_CHANNELS: 'Communication channels',
      EMAIL_TEMPLATES: 'Email templates',
      REGISTRATION_CARDS: 'Registration cards',
      ACTIVITIES: 'Activities',
      ACTIVITY_CATEGORIES: 'Activity categories',
      ACTIVITY_RESERVATIONS: 'Activity reservations',
      WEBAPP_USERS: 'Webapp users',
      CATALOG_PRODUCTS: 'Catalog products',
      CATALOG_CATEGORIES: 'Catalog categories',
      CATALOGS: 'Catalogs',
      TAXES: 'Taxes',
      ORDERS: 'Orders',
      REPORTS: 'Reports',
      TAX_GROUPS: 'Tax groups',
      ACTIVITY_RESERVATION_METHODS: 'Reservation methods'
    },
    resources: {
      UPSERT: 'Create/Edit',
      CREATE:'Create',
      LIST: 'List',
      PAGINATE: 'List',
      REMOVE: 'Remove',
      SET: 'Set',
      REVOKE: 'Invalidate sessions',
      GET: 'Get info',
      EDIT: 'Edit',
      ADD: 'Create',
      TOGGLE: 'Activate/Deactivate',
      DELETE: 'Delete',
      SYNC: 'Sync',
      UPDATE: 'Update',
      ASSIGN: 'Assign',
      PUT: 'Add',
      POST: 'Post',
      RESOLVE: 'Resolve',
      RESERVE: 'Reserve',
      SEND: 'Send',
      TEST: 'Test'
    }
  },
  error: {
    generic_retry: 'Something went worng. Please, try again.',
    not_found: 'Not found',
    no_configuration: 'Configure the application on',
    server_disconnected: 'Server disconnected',
    no_peripheral: 'No peripheral',
    server_error: 'Server error',
    conflict: {
      DISABLED_ACCOUNT: 'Disabled account',
      LIMIT_EXCEEDED: 'This action would exceed the acount\'s consumption limit',
      NOT_ENOUGH_BALANCE: 'Insufficient balance',
      PAYMENT_GATEWAY_COMMUNICATION_ERROR: 'Payment gateway communication error',
      TRANSACTION_ALREADY_EXISTS: 'Transaction already exists',
      NO_OPEN_SHIFT: 'Open turn required'
    },
    no_open_shift_supervisor: 'Open turn required for supervisor',
    auth: {
      long_inactivity_operator: 'Operator has been inactive for too long. Please, contact an administrator',
      disabled_operator: 'Inactive operator',
      unauthorized: 'Unauthorized'
    }
  },
  default_content: {
    warning: 'Warning',
    no_name: 'No name',
    disabled_header: 'Permission required',
    disabled_header_permission: 'Permission for "{resource}" is required'
  },
  peripherals: {
    warnings: {
      disconnected_peripheral_service: 'Peripheral controller is offline',
      printer_not_configured: 'Printer is not configured',
      reader_not_configured: 'Reader is not configured'
    },
    error: {
      socket_connection: 'Peripheral connection error. Check the configuration'
    },
    printer: {
      success: 'Printed successfully'
    }
  },
  pagination: {
    items_per_page: 'Items per page:',
    items_per_page_all: 'All',
    page_text: '{0}-{1} of {2}',
    no_data: 'There are no items',
    items: 'Items',
    many: 'many'
  }
}
