<template>
  <v-app style="position: relative; width: 100%; height: 100%;">
    <navigation-menu v-if="$route.name && !routeNames[$route.name].hidden_menu && auth.credentials" />
    <loading-screen v-if="!state.firstLoad"/>
    <v-main v-else>
      <v-progress-linear
        v-if="$route.name && !routeNames[$route.name].hidden_menu"
        :indeterminate="true"
        :active="state.loading"
        class="ma-0 position-absolute top-0"
        color="primary"
        height="4"
      ></v-progress-linear>
      <v-container fluid fill-height class="pa-0" style="max-width: 100% !important;">
        <v-layout justify-center align-center>
          <v-fade-transition mode="out-in">
            <router-view/>
          </v-fade-transition>
        </v-layout>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="state.message.show"
      bottom
      :color="state.message.color"
      :timeout="state.message.timeout"
    >
      {{ state.message.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import peripheralMixins from './mixins/PeripheralSocket'
import routeNames from '@/router/routes'
export default {
  name: 'App',
  data () {
    return {
      routeNames
    }
  },
  mixins: [
    peripheralMixins
  ],
  beforeDestroy () {
    this.disconnect()
  },
  components: {
    LoadingScreen: () => import('@/components/app-components/LoadingScreen.vue'),
    NavigationMenu: () => import('@/components/app-components/NavigationMenu.vue')
  },
  mounted () {
    this.$store.dispatch('configuration/initialization').then(() => {
    }).catch(() => {}).finally(() => {
      this.$nextTick(() => {
        this.state.firstLoad = true
      })
    })
  },
  computed: {
    ...mapState(['state', 'auth'])
  }
}
</script>

<style lang="scss">

.wrap-none {
  flex-wrap: nowrap !important;
}
.break-word {
  word-break: break-word;
}
.overflow-y-auto {
  overflow-y: auto;
}
.height-auto {
  height: auto !important;
}
.sticky-header {
  th {
    position: sticky !important;
    top: 0;
    z-index: 2;
    background: white;
  }
}
.mobile-pagination {
  .v-data-iterator__actions__select {
    display: none;
  }
  .v-data-iterator__actions__range-controls button {
    margin: 0;
  }
}
.currency {
  input {
    text-align: right;
  }
}
.center-textfield {
  input {
    text-align: center;
  }
}

.required label::after{
  content: '*';
}
.table-resizable {
  max-width: none !important;
  &.resizing {
    cursor: col-resize;
    user-select: none;
  }
  & .v-table__overflow {
    overflow-y: auto;
  }

  th:not(.no-resizable) {
    position: relative;

    // Show resize cursor when hovering over column borders
    &::before {
      cursor: col-resize;
      user-select: none;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 1em;
      transition: all 0.2s ease-in-out;
      border: 0.40em solid transparent;
    }

    &:hover::before {
      background-color: rgb(236, 236, 236);
      border: 0.40em solid rgb(247, 247, 247);
    }

    &:last-of-type::before {
      display: none;
    }
  }
  .no-header-name {
    opacity: 0;
  }
  td:not(.no-resizable), th:not(.no-resizable) {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 0;
  }
  td.no-resizable button, td.no-resizable div {
    margin: auto 4px;
  }
  th.no-resizable {
    width: 100px !important;
    flex: none;
    text-overflow: initial;
    text-align: center;
    overflow-x: overlay;
    left: 0;
  }
  td.no-resizable {
    text-overflow: initial;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow-x: overlay;
    min-width: max-content;
    left: 0;
  }
}

.if-editable {
  display: none;
}
.editable {
  & .if-editable {
    display: block;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: "touch";
}
.overflow-y-hidden {
  overflow-y: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: fit-content !important;
}

.border-bottom__grey {
  border-bottom: 1px rgb(214, 214, 214) solid !important;
}

.margin-auto {
  margin: auto !important;
}
.flex-none {
  flex: none !important;
}
.flex-item {
  flex: 1 auto !important;
}
.flex-column {
  display: flex !important;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-grow-0 {
  flex-grow: 0;
}
.align-baseline {
  align-items: baseline !important;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: flex-end;
}
.clickable {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}

.display-none {
  display: none !important;
}

.margin-left-auto {
  margin-left: auto;
}
.margin-right-auto {
  margin-right: auto;
}
.max-width-100 {
  max-width: 100%;
}
.max-height-100 {
  max-height: 100%;
}
.min-height-100 {
  min-height: 100%;
}
.max-width-fit-content {
  max-width: fit-content;
}
.min-width-fit-content {
  min-width: fit-content;
}
.min-width-100 {
  min-width: 100% !important;
}
.min-width-auto {
  min-width: auto !important;
}
html, body {
  overflow: hidden !important;
  height: 100%;
}

.float-right {
  float: right;
}

.position-absolute {
  position: absolute !important;
}

.login-background {
  background-image: url('~@/../public/bg-tile.png');
  background-repeat: repeat;
}

.full-width {
  width: 100%;
}

.margin-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.not-allowed {
  cursor: not-allowed !important;
}
.show-spaces{
  white-space: pre;
}
.overflow-hidden {
  overflow: hidden;
}
.blue-background-link {
  color: white;
  &:hover {
    text-decoration: underline;
    cursor: pointer
  }
}

// VUETIFY
@media only screen and (min-width: 960px) and (max-width: 1800px) {
  .container {
    max-width: 1200px !important;
  }
}

.v-speed-dial__list .v-btn.v-btn--outlined {
  background-color: white !important;
}

.hidden-table-action {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.editable-border-bottom {
  border-bottom: 1px transparent solid;
  transition: all 0.2s ease-in-out;
}
tr:hover {
  .hidden-table-action {
    opacity: 1 !important;
  }
  .editable-border-bottom {
    border-bottom: 1px rgb(204, 204, 204) solid;
  }
}

.v-main {
  max-height: 100%;
  .v-main__wrap {
    overflow: auto;
    position: relative;
  }
}
.application--wrap {
  min-height: 100% !important;
}
.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}

:not(.v-btn-toggle) > .v-btn:not(.v-btn--floating):not(.v-btn--fab) {
  border-radius: 5px !important;
}
.v-btn.dense {
  padding: 0 5px !important;
}
.headline, .display-1, .display-2, .display-3, .display-4, .subheading, .title {
  &.raleway {
    font-family: 'Raleway', sans-serif !important;
  }
}

.login-logo {
  height: auto;
  max-width: 80%;
}

// BOOTSTRAP
.row {
  margin-right: 0;
  margin-left: 0;
}

// ANIMATION
@keyframes DrawLine {
  to { stroke-dashOffset: 0; }
}
@keyframes BounceOpacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FadeStroke {
  to { stroke-opacity: 0; }
}
@keyframes FillIn {
  from { fill-opacity: 0; }
  to { fill-opacity: 1; }
}

.Animate-Opacity {
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  animation-name: BounceOpacity;
  animation-duration:2s;
  animation-delay: 1s;
}
.Animate-Draw {
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1, 1, 1, infinite;

  animation-name: DrawLine, FadeStroke, FillIn, BounceOpacity;
  animation-duration: 2s, 1s, 1s, 2s;
  animation-delay: 0s, 0s, 0s, 1s;
}
</style>
