import camelCaseKeys from 'camelcase-keys'
import { Tax } from '@/entities/Tax'
export class Pass {
  constructor (params = {}) {
    const { id, name, description, pricingGroupsWithPrice, taxes } = camelCaseKeys(params || {}, { deep: true })
    this.id = id || null
    this.name = name || []
    this.description = description || []
    this.pricingGroupsWithPrice = pricingGroupsWithPrice || []
    this.taxes = taxes ? taxes.map((t) => new Tax(t)) : []
  }
}