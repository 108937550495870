import camelCaseKeys from 'camelcase-keys'

export class Shift {
  constructor (params = {}) {
    const { id, operatorId, station, stationId, printerId, initialAccountingState, finalAccountingState, openedByOperatorId, closedByOperatorId, openedAt, closedAt } = camelCaseKeys(params)
    this.id = id || null
    this.operatorId = operatorId || null
    this.station = station || null
    this.stationId = stationId || null
    this.printerId = printerId || null
    this.initialAccountingState = initialAccountingState ? initialAccountingState.map((a) => {
      return camelCaseKeys(a)
    }) : null
    this.finalAccountingState = finalAccountingState ? finalAccountingState.map((a) => {
      return camelCaseKeys(a)
    }) : null
    this.openedByOperatorId = openedByOperatorId || null
    this.closedByOperatorId = closedByOperatorId || null
    this.openedAt = openedAt || null
    this.closedAt = closedAt || null
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      operatorId: item.operatorId,
      stationId: item.stationId,
      initialAccountingState: item.initialAccountingState.filter((a) => { return !!a.amount })
    }
  }
}
