export class Role {
  constructor (params = {}) {
    this.id = params.id ? params.id : null
    this.sequence = params.sequence ? params.sequence : null
    this.name = params.name ? params.name : null
    this.resources = params.resources ? params.resources : []
    this.managedByRoles = []
    this.managedByRoleIds = params.managed_by_role_ids ? params.managed_by_role_ids : []
    this.simplifiedResources = params.resources ? params.resources.map((resource) => { return resource.identifier }) : []
  }
  serialize (editedFields) {
    var item = JSON.parse(JSON.stringify(this))
    item.resources = this.resources.map((resource) => resource.identifier)
    Object.keys(editedFields).forEach(key => {
      item[key] = editedFields[key]
    })
    return {
      id: item.id,
      name: item.name,
      managedByRoleIds: item.managedByRoleIds,
      resources: item.resources.map((resource) => resource.identifier || resource)
    }
  }
}
